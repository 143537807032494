import React from "react";
import Loader from "../../../../components/Loader/Loader";
import moment from "moment";

const InvoiceStructure = ({invoiceDetails,loginData, loading} ) => {

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <table className="w-full border-collapse border-2 border-[#222]">
            <thead>
              <tr>
                <td colSpan="4" className="border-b-2 border-[#222]">
                  <h2 className="my-4 text-2xl font-bold text-center text-gray-700 underline">
                    {loginData?.default_community?.community_name}
                  </h2>
                  <div className="w-full font-normal text-center">
                    (REGN. NO. {loginData?.default_community?.regn_no} Dated -
                    {moment(loginData?.default_community?.date_of_regn)?.format(
                      "DD-MM-YYYY"
                    )}
                    )
                  </div>
                  <div className="w-full text-sm font-normal text-center text-gray-700">
                    {loginData?.default_community?.office_address}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="border-b-2 border-r-2 border-[#222]">
                  &nbsp;
                </td>
                <td className="border-b-2 border-r-2 border-[#222]">&nbsp;</td>
                <td className="w-3/5 border-b-2 border-[#222]">&nbsp;</td>
              </tr>
              <tr>
                <td
                  colSpan="2"
                  className="border-b-2 border-r-2 border-[#222] p-1"
                  height="30"
                >
                  Invoice Number : {invoiceDetails?.data?.invoice_no}
                </td>
                <td
                  colSpan="2"
                  className="text-right border-b-2 border-r-2 border-[#222] p-1"
                >
                  Invoice Date :{" "}
                  {moment(invoiceDetails?.data?.invoice_date).format(
                    "DD-MM-YYYY"
                  ) || "-"}
                </td>
              </tr>
              <tr height="30">
                <td
                  colSpan="2"
                  className="border-b-2 border-r-2 border-[#222] p-1"
                >
                  Member Name: {invoiceDetails?.data?.membername}
                </td>
                <td
                  colSpan="2"
                  className="border-b-2 border-r-2 border-[#222] text-right p-1"
                >
                  Bill Period: {invoiceDetails?.data?.invoice_period}
                </td>
              </tr>
              <tr height="30">
                <td
                  colSpan="2"
                  className="border-b-2 border-r-2 border-[#222] p-1"
                >
                  Unit: Wing No. - {invoiceDetails?.data?.wing_no} ,Flat No. -{" "}
                  {invoiceDetails?.data?.flat_no}, Area -{" "}
                  {invoiceDetails?.data?.flatarea}
                </td>
                <td
                  colSpan="2"
                  className="border-b-2 border-r-2 border-[#222] text-right p-1"
                >
                  Payment Due Date:{" "}
                  {moment(invoiceDetails?.data?.payment_duedate).format(
                    "DD-MM-YYYY"
                  ) || "-"}
                </td>
              </tr>
              <tr className="border-b-2 border-[#222]" height="30">
                <td
                  colSpan="4"
                  className="border-b-2 border-[#222] text-left p-1"
                >
                  Parking No. - {invoiceDetails?.data?.parkingno}
                </td>
              </tr>
              <tr height="30">
                <td
                  colSpan="4"
                  className="border-b-2 border-[#222] text-right p-1"
                >
                  Amount in Rs.
                </td>
              </tr>
              <tr height="30">
                <th
                  width="33%"
                  className="border-b-2 border-r-2 border-[#222] text-left p-1"
                >
                  Item
                </th>
                <th
                  width="20%"
                  className="border-b-2 border-r-2 border-[#222] text-left p-1"
                >
                  Invoice Amount
                </th>
                {invoiceDetails?.data?.interestMethod !=="dynamic" && <th
                  width="20%"
                  className="border-b-2 border-r-2 border-[#222] text-left p-1"
                >
                  Paid Amount
                </th> }
                <th
                  width="27%"
                  className="border-b-2 border-r-2 border-[#222] text-left p-1"
                >
                  {invoiceDetails?.data?.interestMethod ==="dynamic"?"Dynamic Amount":"Due Amount"}
                </th>
                {invoiceDetails?.data?.interestMethod ==="dynamic" && <th
                  width="20%"
                  className="border-b-2 border-r-2 border-[#222] text-left p-1"
                >
                </th> }
              </tr>
            </thead>
            <tbody>

              {invoiceDetails?.data?.invoicedetail?.main_head?.map(
                (item, index) => {
                  const [key, value] = Object.entries(item)[0];
                  return (
                    <tr
                      key={index}
                      className="border-b-2 border-[#222]"
                      height="30"
                    >
                      <td className={`border-b-2 border-r-2 border-[#222] p-1`}>
                        {value?.headname}
                      </td>
                      <td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.amount}
                      </td>
                      {invoiceDetails?.data?.interestMethod !=="dynamic" &&<td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.paidamount}
                      </td>}
                      <td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.amount2}
                      </td>
                    </tr>
                  );
                }
              )}

              {invoiceDetails?.data?.invoicedetail?.more_head?.map(
                (item, index) => {
                  const [key, value] = Object.entries(item)[0];
                  return (
                    <tr
                      key={index}
                      className="border-b-2 border-[#222]"
                      height="30"
                    >
                      <td className={`border-b-2 border-r-2 border-[#222] p-1`}>
                        {value?.headname}
                      </td>
                      <td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.amount}
                      </td>
                      {invoiceDetails?.data?.interestMethod !=="dynamic" &&<td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.paidamount}
                      </td>}
                      <td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.amount2}
                      </td>
                    </tr>
                  );
                }
              )}


              <tr className="border-b-2 border-[#222]" height="30">
                <td
                  className={`border-b-2 border-r-2 border-[#222] p-1 ${
                    invoiceDetails?.data?.invoicedetail?.subtotal?.headname ===
                      "Sub Total" ||
                    invoiceDetails?.data?.invoicedetail?.subtotal?.headname ===
                      "Grand Total"
                      ? "font-semibold"
                      : ""
                  }`}
                >
                  {invoiceDetails?.data?.invoicedetail?.subtotal?.headname}
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {invoiceDetails?.data?.invoicedetail?.subtotal?.amount}
                </td>
                {invoiceDetails?.data?.interestMethod !=="dynamic" &&<td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {invoiceDetails?.data?.invoicedetail?.subtotal?.paidamount}
                </td>}
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {invoiceDetails?.data?.invoicedetail?.subtotal?.amount2}
                </td>
              </tr>

              {invoiceDetails?.data?.invoicedetail?.arrear_head?.map(
                (item, index) => {
                  const [key, value] = Object.entries(item)[0];
                  return (
                    <tr
                      key={index}
                      className="border-b-2 border-[#222]"
                      height="30"
                    >
                      <td className={`border-b-2 border-r-2 border-[#222] p-1`}>
                        {value?.headname}
                      </td>
                      <td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.amount}
                      </td>
                      {invoiceDetails?.data?.interestMethod !=="dynamic" &&<td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.paidamount}
                      </td>}
                      <td
                        className={`border-b-2 border-r-2 border-[#222] p-1 `}
                      >
                        {value?.amount2}
                      </td>
                    </tr>
                  );
                }
              )}

              <tr className="border-b-2 border-[#222]" height="30">
                <td
                  className={`border-b-2 border-r-2 border-[#222] p-1 ${
                    invoiceDetails?.data?.invoicedetail?.subtotal?.headname ===
                      "Sub Total" ||
                    invoiceDetails?.data?.invoicedetail?.subtotal?.headname ===
                      "Grand Total"
                      ? "font-semibold"
                      : ""
                  }`}
                >
                  &nbsp;
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  &nbsp;
                </td>
                {invoiceDetails?.data?.interestMethod !=="dynamic" &&<td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  &nbsp;
                </td>}
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  &nbsp;
                </td>
              </tr>
              

              <tr className="border-b-2 border-[#222]" height="30">
                <td
                  className={`border-b-2 border-r-2 border-[#222] p-1 font-semibold`}
                >
                  Grand Total
                </td>
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {invoiceDetails?.data?.invoicedetail?.grandtotal?.amount}
                </td>
                {invoiceDetails?.data?.interestMethod !=="dynamic" && <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {invoiceDetails?.data?.invoicedetail?.grandtotal?.paidamount}
                </td>}
                <td className={`border-b-2 border-r-2 border-[#222] p-1 `}>
                  {invoiceDetails?.data?.invoicedetail?.grandtotal?.amount2}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default InvoiceStructure;
