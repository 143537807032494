import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance2 from '../../../axiosInstance2'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';



export async function getManageAdvanceListReq(action) {
    const BODY={
        'id': action.data?.societyId,
        'wing_id': action.data?.wingId,
        'flat_no': action.data?.flatId,
        'page': isNaN(parseInt(action.data?.page)-1) ? 'all' : parseInt(action.data?.page)-1,
        'limit': action.data?.pageLimit,
        'payment_status': action.data?.status,
        'from_date': action.data?.startDate,
        'to_date': action.data?.endDate,
        'search_by': action.data?.searchBy,
        'invoice_no': action.data?.searchText,
        'typeid': action.data?.InvoiceType,
    }

    return axiosInstance2.post(apiEndpoints.GET_ADVANCE_NOTE_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getAdvanceNoteDetailsReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('drcrid', action.data?.AdvanceNoteId);

    return axiosInstance2.post(apiEndpoints.GET_ADVANCE_NOTE_DETAILS_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function addAdvanceNoteReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid": action.data.invoiceType,
        "debitor_type": action.data.memberType,
        "advance_date": action.data.date,
        "Hard_copy_voucher": action.data.voucher,
        ...(action.data.wingId && {"wing_id": action.data.wingId}),
        ...(action.data.flatId && {"flat_id": action.data.flatId}),
        ...(action.data.memberId && {"member_id": action.data.memberId}),
        ...(action.data.memberGLCode && {"member_glcode": action.data.memberGLCode}),
        ...(action.data.memberGLName && {"member_glacc": action.data.memberGLName}),
        ...(action.data.vendorId && {"vendor_id": action.data.vendorId}),
        ...(action.data.memberGlCode && {"vendor_glcode1": action.data.vendorGLCode}),
        ...(action.data.vendorGLName && {"vendor_glacc1": action.data.vendorGLName}),
        
        "cheque_no": action.data.chequeNumber,
        "bank_name": action.data.bankName,
        "cheque_date": action.data.chequeDate,
        "payment_date": action.data.paymentDate,
        
        "amount": action.data.amount,
        "remarks": action.data.remarks,

        "send_email": action.data.sendEmail,
        "send_sms": action.data.sendSMS,
        "debitaccount": action.data.debitAccountId,
    }
    //console.log(action.data)


    return axiosInstance2.post(apiEndpoints.ADD_ADVANCE_NOTE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function updateAdvanceNoteReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('pageid', action.data?.societyId);
    bodyFormData.append('typeid', action.data?.invoiceType);
    bodyFormData.append('journalid', action.data?.jounralId);
    bodyFormData.append('drcrid', action.data?.advanceNoteId);
    bodyFormData.append('registerId', action.data?.registerId);
    bodyFormData.append('debitor_type', action.data?.memberType);
    bodyFormData.append('advance_date', action.data?.date);
    bodyFormData.append('Hard_copy_voucher', action.data?.voucher);
    bodyFormData.append('debitaccount', action.data?.debitAccountId);
    bodyFormData.append('wing_id', action.data?.wingId);
    bodyFormData.append('flat_id', action.data?.flatId);
    bodyFormData.append('userId', action.data?.userId);
    bodyFormData.append('creditaccount', action.data?.creditAccountId);
    bodyFormData.append('membergl', action.data?.userName);
    //bodyFormData.append('additional', action.data?.userId);
    bodyFormData.append('selectid', action.data?.loginType);

    bodyFormData.append('loginid', action.data?.loginId);
    bodyFormData.append('loginType', action.data?.loginType);
    bodyFormData.append('cheque_no', action.data?.chequeNumber);
    bodyFormData.append('bank_name', action.data?.bankName);
    bodyFormData.append('cheque_date', action.data?.chequeDate);
    bodyFormData.append('payment_date', action.data?.paymentDate);
    bodyFormData.append('amount', action.data?.amount);
    bodyFormData.append('remarks', action.data?.remarks);
    bodyFormData.append('send_email', action.data?.sendEmail);
    bodyFormData.append('send_sms', action.data?.sendSMS);


    return axiosInstance2.post(apiEndpoints.UPDATE_ADVANCE_NOTE_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function deleteAdvanceNoteReq(action) {
    const BODY={
        'drcrid': action.data?.advanceNoteId,
        'pageid': action.data?.societyId,
        'journalid': action.data?.jounralId
    }

    return axiosInstance2.post(apiEndpoints.DELETE_ADVANCE_NOTE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function printAdvancetNoteInvoiceReq(action) {
    const BODY={
        "drcrid" : action.data.advanceNoteId,
        'id': action.data?.societyId,
    }


    return axiosInstance2.post(apiEndpoints.PRINT_ADVANCE_NOTE_RECIEPT_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function getNonMemberListReq(action) {
    const BODY={
        'id': action.data?.societyId,
    }

    return axiosInstance2.post(apiEndpoints.GET_NON_MEMBER_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function uploadAdvanceNoteExcelReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('id', action.data?.societyId);
    bodyFormData.append('typeid', action.data?.invoiceType);
    bodyFormData.append('excel', action.data?.file);


    return axiosInstance2.post(apiEndpoints.UPLOAD_ADVANCE_NOTE_EXCEL_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function saveAdvanceNoteExcelReq(action) {
    let {uploadingExcelData} = action.data;
    uploadingExcelData = uploadingExcelData?.filter(item=> item.selected)

    const BODY ={
        "pageid": action.data.societyId,
        "typeid": action.data.invoiceType,
        "invid": uploadingExcelData?.map((item, index)=> index),
        "invoice": uploadingExcelData?.map(item=> 'Yes'),
        "advance_type": uploadingExcelData?.map(item=> item.type),
        "wing_id": uploadingExcelData?.map(item=> item.wingid),
        "flat_id": uploadingExcelData?.map(item=> item.flatid),
        "member_id": uploadingExcelData?.map(item=> item.member_id),
        "member_glcode": uploadingExcelData?.map(item=> item.member_glcode),
        "member_glacc": uploadingExcelData?.map(item=> item.member_glacc),
        "billdate": uploadingExcelData?.map(item=> item.date),
        "voucher": uploadingExcelData?.map(item=> item.voucher_no),
        "debitaccount": uploadingExcelData?.map(item=> item.debit_ac),
        "debitaccountname": uploadingExcelData?.map(item=> item.debit_ac_name),
        "cheque_number": uploadingExcelData?.map(item=> item.cheque_number),
        "bank_name": uploadingExcelData?.map(item=> item.bank_name),
        "cheque_date": uploadingExcelData?.map(item=> item.cheque_date),
        "payment_date": uploadingExcelData?.map(item=> item.payment_date),
        "amount": uploadingExcelData?.map(item=> item.amount),
        "remark": uploadingExcelData?.map(item=> item.remark),
    }


    return axiosInstance2.post(apiEndpoints.SAVE_ADVANCE_NOTE_EXCEL_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function downloadAdvanceExcelSampleReq(action) {

    return axiosInstance2.post(`${apiEndpoints.DOWNLOAD_ADVANCE_EXCEL_SAMPLE_API}`, {}, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}