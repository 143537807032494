import React, { useState } from "react";
import PropTypes from "prop-types";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { red } from '@mui/material/colors';
import FormLabel from '@mui/material/FormLabel';
import {TextInputFM}  from "../InputFields/TextInput";
import {NumberInputFM}  from "../InputFields/NumberInput";
// import TextField from '@mui/material/TextField';
import Button from "../Button/Button";
import  CheckboxFM  from "../Checkbox/Checkbox";
import {DropdownFM2} from "../Dropdown/dropdown2";
import { EDIT_MORE_HEADS_ENDPOINT } from "../../Routing/routes";
import { CircularProgress } from "@mui/material";
import { ErrorMessage } from "formik";


const InvoiceHeadSettingGrid = ({
    loading,
    data,
    setHeadDeatail,
    handleNavigation,
    handleDeleteHead,
    deletehead,
    typeId,
    setFieldValue
}) => {
    const handleChange = (headdata) => {
        setHeadDeatail({ data, ...headdata })
    }
    const ordernumber = (value) => {
        var result =false
        //console.log(value,'value')
        if(data?.main_head){
            data?.main_head.map((key) => {
                //console.log(parseInt(key?.order),'order')
                if(parseInt(key.order) !== 0 && parseInt(key.order) === value){
                    //alert("hieeee")
                    result=  true
                }
            })
        }
        if(data?.more_head){
            data?.more_head.forEach((key) => {
                if(parseInt(key.order) !== 0 && parseInt(key.order) === value){
                    result=  true
                }
            })
        }
        if(data?.arrear_head){
            data?.arrear_head.forEach((key) => {
                if(parseInt(key.order) !== 0 && parseInt(key.order) === value){
                    result=  true
                }
            })
        }
        return result;
    }
    return (
        <div className="grid gap-2 p-5 2xl:grid-cols-2 lg:grid-cols-2">
            {data?.main_head ? data?.main_head.map((item, index) => (
                <div className="border rounded-lg border-[#CCCCCC] my-1">
                    <div className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b">
                        <div className="flex items-center w-full">
                            <div className="text-[#000000] text-sm font-semibold grow  flex justify-between ">
                                <span>{item.invoice_head}</span>
                                <span>
                                {item.id === "roundoff" ? <></> :<CheckboxFM
                                        text="Active"
                                        className="-mt-3 text-xs"
                                        initialchecked={
                                            item?.active === "Yes"
                                                ? true
                                                : false
                                        }
                                        name={`headDetail.main_head.${index}.active`}
                                        onChange={(e) => {
                                           
                                            //console.log(e.target.checked)
                                            setFieldValue(`headDetail.main_head.${index}.active`,e.target.checked ? "Yes" : "No")
                                            //console.log( data.main_head[item.id].active);
                                            //data.main_head[item.id].active = e.target.checked ? "Yes" : "No";
                                            //handleChange(data)
                                        }}
                                    />}
                                </span>
                            </div>
                        </div>
                    </div>
                    <table className="w-full p-1 text-sm" aria-label="table">
                        <tbody>
                        {item.id === "roundoff" ? <></> :<tr className="border-b-2">
                                <td className="px-2 py-1">
                                    Order :
                                </td>
                                <td className="px-2 py-1">
                                    <NumberInputFM
                                        placeholder="Enter Order"
                                        className="w-[300px]"
                                        value={item?.order}
                                        type="decimal"
                                        name={`headDetail.main_head.${index}.order`}
                                        onChange={ (e) => {
                                            if(ordernumber(parseInt(e.target.value))){
                                                setFieldValue(`headDetail.main_head.${index}.mainerror`,true)
                                
                                                //document.getElementById(item.id).innerText = "Order number already exists"
                                            }else{
                                                setFieldValue(`headDetail.main_head.${index}.mainerror`,false)
                                                //document.getElementById(item.id).innerText = " "
                                            }
                                            setFieldValue(`headDetail.main_head.${index}.order`,parseInt(e.target.value))
                                            // data.main_head[item.id].order = !isNaN(e.target.value) ? parseFloat(e.target.value) : "";
                                            // handleChange(data)
                                            //handleCalculate(data,index)
                                        }}
                                    />
                                    
                                     <div className={`text-red-500 text-xs italic h-auto text-wrap break-words`} id={item.id}></div> 
                                </td>
                            </tr>}
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    Tarrif Name :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter Tarrif Name"
                                        className="w-[300px]"
                                        value={item?.tariff_header|| ""}
                                        type="text"
                                        name={`headDetail.main_head.${index}.tariff_header`}
                                        // onChange={(e) => {
                                        //     data.main_head[item.id].tariff_header = e.target.value;
                                        //     handleChange(data)
                                        //     //handleCalculate(data,index)
                                        // }}
                                    />
                                </td>
                            </tr >
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    G/L Accounts :
                                </td>
                                <td className="px-2 py-1">
                                    <DropdownFM2
                                        options={data.gl_details.map((detail) => {
                                            return {
                                                value: detail.ac_code,
                                                label: detail.name
                                            }
                                        })}
                                        value={item?.gl_code !== '' ? {
                                            label: item?.gl_name,
                                            value: item?.gl_code,
                                        } : null}
                                        name={`headDetail.main_head.${index}.gl_code`}
                                        onSelect={(e) => {
                                            //console.log(e)
                                            setFieldValue(`headDetail.main_head.${index}.gl_code`,e.value)
                                            setFieldValue(`headDetail.main_head.${index}.gl_name`,e.label)
                                            // data.main_head[item.id].gl_code = e.value;
                                            // data.main_head[item.id].gl_name = e.label;
                                            // handleChange(data)
                                        }}
                                        placeholder="Select Gl Account"
                                        width="300px"
                                        height="36px"
                                    />
                                </td>
                            </tr>
                            {["servicetax", "Sb_Cess", "debinote", "Kk_Cess","roundoff"].includes(item.id) ? <></> : <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    HSN code :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter HSN code"
                                        className="w-[300px]"
                                        value={item?.hsn_code || ""}
                                        type="decimal"
                                        name={`headDetail.main_head.${index}.hsn_code`}
                                        // onChange={(e) => {
                                            
                                        //     data.main_head[item.id].hsn_code = !isNaN(e.target.value) ? parseFloat(e.target.value) : "";
                                        //     handleChange(data)
                                        //     //handleCalculate(data,index)
                                        // }}
                                    />
                                </td>
                            </tr>}
                            {["servicetax", "Sb_Cess", "debinote", "Kk_Cess","roundoff"].includes(item.id) ? <></> : <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="GST"
                                        className="m-3"
                                        initialchecked={
                                            item?.gst_applicable === "Yes" ? true : false
                                        }
                                        arrear_head
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.main_head.${index}.gst_applicable`,e.target.checked ? "Yes" : "No")
                                            //data.main_head[item.id].gst_applicable = e.target.checked ? "Yes" : "No";
                                            //handleChange(data)
                                        }}
                                    />
                                </td>
                                <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="Exemption Not Applicable"
                                        className="m-3"
                                        initialchecked={
                                            item?.excemption === "Y" ? true : false
                                        }
                                        name={`headDetail.main_head.${index}.excemption`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.main_head.${index}.excemption`,e.target.checked ? "Y" : "N")
                                            // data.main_head[item.id].excemption = e.target.checked ? "Y" : "N";
                                            // handleChange(data)
                                        }}
                                    />
                                </td>
                            </tr>}
                            {["servicetax", "Sb_Cess", "debinote", "Kk_Cess","roundoff"].includes(item.id) ? <></> : <tr className="border-b-2">
                                {/* <td className="px-2 py-1">
                                        <CheckboxFM
                                            text="Noc"
                                            className="m-3"
                                            initialchecked={
                                                item?.gst_applicable === "Yes" ? true : false
                                            }
                                            onChange={(e) => {
                                            }}
                                        />
                                    </td> */}
                                <td className="px-2 py-1 text-center" colSpan={2}>
                                    <FormControl className="m-3" >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name={`headDetail.main_head.${index}.servicegood`}
                                            value={item?.servicegood || 'Y'}
                                            onChange={(e) => {
                                                setFieldValue(`headDetail.main_head.${index}.servicegood`,e.target.value)
                                                // data.main_head[item.id].servicegood = e.target.value;
                                                // handleChange(data)
                                            }}
                                        >
                                            <FormControlLabel value="Y" className="text-sm" control={<Radio sx={{ color: "#CF001C", '&.Mui-checked': { color: "#CF001C", }, }} />} label="Service" />
                                            <FormControlLabel value="N" className="text-sm" control={<Radio sx={{ color: "#CF001C", '&.Mui-checked': { color: "#CF001C", }, }} />} label="Good" />
                                        </RadioGroup>
                                    </FormControl>
                                </td>

                            </tr>}
                        </tbody>
                    </table>
                    {/* <div className="w-full p-5">col 1</div> */}
                </div>
            )) : <></>}
            {data?.more_head ? data?.more_head.map((item,index) => (
                <div className="border rounded-lg border-[#CCCCCC] my-1">
                    <div className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b">
                        <div className="flex items-center w-full">
                            <div className="text-[#000000] text-sm font-semibold grow  flex justify-between items-center ">
                                <div><span>{item.invoice_head}</span></div>
                                <div className="flex items-center ">
                                    <CheckboxFM
                                        text="Active"
                                        className="-mt-3 text-xs"
                                        initialchecked={
                                            item?.active === "Yes"
                                                ? true
                                                : false
                                        }
                                        name={`headDetail.more_head.${index}.active`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.more_head.${index}.active`,e.target.checked ? "Yes" : "No")
                                            // data.more_head[item.id].active = e.target.checked ? "Yes" : "No";
                                            // handleChange(data)
                                        }}
                                    />
                                    {/* {item?.type=="normal" ? */}
                                    <Button label="Edit" className="ml-2" onClick={
                                        handleNavigation(`${EDIT_MORE_HEADS_ENDPOINT}/${typeId}/${item.id}`)
                                    } />
                                    <Button label={
                                        <span className="inline-block w-12">
                                            {
                                                deletehead===item.id ?
                                                    <CircularProgress sx={{ color: "white" }} size={17} />
                                                    :
                                                    'Delete'
                                            }
                                        </span>
                                    } className="ml-2" onClick={()=>handleDeleteHead(item.id)
                                        // handleNavigation(`${EDIT_MORE_HEADS_ENDPOINT}/${item.id}`)
                                    } />
                                    {/* :<></>}
                                    // {item?.type=="utility" ?
                                    // <Button label="Utility Setting" className="ml-2" onClick={
                                    //     handleNavigation(`${EDIT_MORE_HEADS_ENDPOINT}/${item.id}`)
                                    // } />:<></>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="w-full p-1 text-sm" aria-label="table">
                        <tbody>
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    Order :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter Order Number"
                                        className="w-[300px]"
                                        value={item?.order}
                                        type="decimal"
                                        name={`headDetail.more_head.${index}.order`}
                                        onChange={ (e) =>  {
                                            if(ordernumber(parseInt(e.target.value))){
                                                setFieldValue(`headDetail.more_head.${index}.moreerror`,true)
                                                //document.getElementById(item.id).innerText = "Order number already exists"
                                            }else{
                                                setFieldValue(`headDetail.more_head.${index}.moreerror`,false)
                                                //document.getElementById(item.id).innerText = " "
                                            }
                                            setFieldValue(`headDetail.more_head.${index}.order`,parseInt(e.target.value))
                                            
                                        }}
                                    />
                                    <div className={`text-red-500 text-xs italic h-auto text-wrap break-words`} id={item.id}></div> 
                                </td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    Tarrif Name :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter Tarrif Name"
                                        className="w-[300px]"
                                        value={item?.invoice_head || ""}
                                        type="text"
                                        name={`headDetail.more_head.${index}.invoice_head`}
                                        // onChange={(e) => {
                                        //     data.more_head[item.id].invoice_head = e.target.value;
                                        //     handleChange(data)
                                        //     //handleCalculate(data,index)
                                        // }}
                                    />
                                </td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    G/L Accounts :
                                </td>
                                <td className="px-2 py-1">
                                    <DropdownFM2
                                        options={data.gl_details.map((detail) => {
                                            return {
                                                value: detail.ac_code,
                                                label: detail.name
                                            }
                                        })}
                                        value={item?.gl_code !== '' ? {
                                            label: item?.gl_name,
                                            value: item?.gl_code,
                                        } : null}
                                        name={`headDetail.more_head.${index}.invoice_head`}
                                        onSelect={(e) => {
                                            setFieldValue(`headDetail.more_head.${index}.gl_code`,e.value)
                                            setFieldValue(`headDetail.more_head.${index}.gl_name`,e.label)
                                            //console.log(e)
                                            // data.more_head[item.id].gl_code = e.value;
                                            // data.more_head[item.id].gl_name = e.label;
                                            // handleChange(data)
                                        }}
                                        placeholder="Select Gl Account"
                                        width="300px"
                                        height="36px"
                                    />
                                </td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    HSN code :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter HSN Code"
                                        className="w-[300px]"
                                        value={item?.hsn_code || ""}
                                        type="decimal"
                                        name={`headDetail.more_head.${index}.hsn_code`}
                                        // onChange={(e) => {
                                        //     data.more_head[item.id].hsn_code = !isNaN(e.target.value) ? parseFloat(e.target.value) : "";
                                        //     handleChange(data)
                                        //     //handleCalculate(data,index)
                                        // }}
                                    />
                                </td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="GST"
                                        className="m-3"
                                        initialchecked={
                                            item?.gst_applicable === "Yes" ? true : false
                                        }
                                        name={`headDetail.more_head.${index}.gst_applicable`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.more_head.${index}.gst_applicable`,e.target.checked ? "Yes" : "No")
                                            //data.more_head[item.id].gst_applicable = e.target.checked ? "Yes" : "No";
                                            //handleChange(data)
                                        }}
                                    />
                                </td>
                                <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="Exemption Not Applicable"
                                        className="m-3"
                                        initialchecked={
                                            item?.excemption === "Y" ? true : false
                                        }
                                        name={`headDetail.more_head.${index}.excemption`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.more_head.${index}.excemption`,e.target.checked ? "Y" : "N")
                                            // data.more_head[item.id].excemption = e.target.checked ? "Y" : "N";
                                            // handleChange(data)
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr className="border-b-2">
                                {/* <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="Noc"
                                        className="m-3"
                                        initialchecked={
                                            item?.gst_applicable === "Yes" ? true : false
                                        }
                                        onChange={(e) => {
                                        }}
                                    />
                                </td> */}

                                <td className="px-2 py-1 text-center" colSpan={2}>
                                    <FormControl className="m-3" >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name={`headDetail.more_head.${index}.servicegood`}
                                            value={item?.servicegood || 'Y'}
                                            onChange={(e) => {
                                                setFieldValue(`headDetail.more_head.${index}.servicegood`,e.target.value)
                                                // data.more_head[index].servicegood = e.target.value;
                                                // handleChange(data)
                                            }}
                                        >
                                            <FormControlLabel value="Y" className="text-sm" control={<Radio sx={{ color: "#CF001C", '&.Mui-checked': { color: "#CF001C", }, }} />} label="Service" />
                                            <FormControlLabel value="N" className="text-sm" control={<Radio sx={{ color: "#CF001C", '&.Mui-checked': { color: "#CF001C", }, }} />} label="Good" />
                                        </RadioGroup>
                                    </FormControl>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    {/* <div className="w-full p-5">col 1</div> */}
                </div>

            )) : <></>}
            {data?.arrear_head ? data?.arrear_head.map((item,index) => (
                <div className="border rounded-lg border-[#CCCCCC] my-1">
                    <div className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-lg p-3 border-b">
                        <div className="flex items-center w-full">
                            <div className="text-[#000000] text-sm font-semibold grow  flex justify-between ">
                                <span>{item.invoice_head}</span>
                                <span>
                                    <CheckboxFM
                                        text="Active"
                                        className="-mt-3 text-xs"
                                        initialchecked={
                                            item?.active === "Yes"
                                                ? true
                                                : false
                                        }
                                        name={`headDetail.arrear_head.${index}.active`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.arrear_head.${index}.active`,e.target.checked ? "Yes" : "No")
                                            // data.arrear_head[item.id].active = e.target.checked ? "Yes" : "No";
                                            // handleChange(data)
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <table className="w-full p-1 text-sm" aria-label=" table">
                        <tbody>
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    Order :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter Order"
                                        className="w-[300px]"
                                        value={item?.order}
                                        type="decimal"
                                        name={`headDetail.arrear_head.${index}.order`}
                                        onChange={ (e) =>  {
                                            if(ordernumber(parseInt(e.target.value))){
                                                setFieldValue(`headDetail.arrear_head.${index}.arrearerror`,true)
                                                //document.getElementById(item.id).innerText = "Order number already exists"
                                            }else{
                                                setFieldValue(`headDetail.arrear_head.${index}.arrearerror`,false)
                                                //document.getElementById(item.id).innerText = " "
                                            }
                                            setFieldValue(`headDetail.arrear_head.${index}.order`,parseInt(e.target.value))
                                            
                                        }}
                                        // onChange={(e) => {
                                        //     data.arrear_head[item.id].order = !isNaN(e.target.value) ? parseFloat(e.target.value) : "";
                                        //     handleChange(data)
                                        //     //handleCalculate(data,index)
                                        // }}
                                    />
                                    <div className={`text-red-500 text-xs italic h-auto text-wrap break-words`} id={item.id}></div> 
                                </td>
                            </tr>
                            <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    Tarrif Name :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter Tarrif Name"
                                        className="w-[300px]"
                                        value={item?.tariff_header|| ""}
                                        type="text"
                                        name={`headDetail.arrear_head.${index}.tariff_header`}
                                        // onChange={(e) => {
                                        //     data.arrear_head[item.id].tariff_header = e.target.value;
                                        //     handleChange(data)
                                        //     //handleCalculate(data,index)
                                        // }}
                                    />
                                </td>
                            </tr>
                            {item?.invoice_head === "Current Period Interest" ? <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    G/L Accounts :
                                </td>
                                <td className="px-2 py-1">
                                    <DropdownFM2
                                        options={data.gl_details.map((detail) => {
                                            return {
                                                value: detail.ac_code,
                                                label: detail.name
                                            }
                                        })}
                                        value={item?.gl_code !== '' ? {
                                            label: data.arrear_head[index].gl_name,
                                            value: data.arrear_head[index].gl_code,
                                        } : null}
                                        name={`headDetail.arrear_head.${index}.gl_code`}
                                        onSelect={(e) => {
                                            //console.log(e)
                                            setFieldValue(`headDetail.arrear_head.${index}.gl_code`,e.value)
                                            setFieldValue(`headDetail.arrear_head.${index}.gl_name`,e.label)
                                            // data.arrear_head[item.id].gl_code = e.value;
                                            // data.arrear_head[item.id].gl_name = e.label;
                                            // handleChange(data)
                                        }}
                                        placeholder="Select Gl Account"
                                         width="300px"
                                        height="36px"
                                    />
                                </td>
                            </tr> : <></>}
                            {item?.invoice_head === "Current Period Interest" ? <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    HSN code :
                                </td>
                                <td className="px-2 py-1">
                                    <TextInputFM
                                        placeholder="Enter HSN code"
                                        className="w-[300px]"
                                        value={item?.hsn_code || ""}
                                        type="decimal"
                                        name={`headDetail.arrear_head.${index}.hsn_code`}
                                        // onChange={(e) => {
                                        //     data.arrear_head[item.id].hsn_code = e.target.value;
                                        //     handleChange(data)
                                        // }}
                                    />
                                </td>
                            </tr> : <></>}
                            {item?.invoice_head === "Current Period Interest" ? <tr className="border-b-2">
                                <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="GST"
                                        className="m-3"
                                        initialchecked={
                                            item?.gst_applicable === "Yes" ? true : false
                                        }
                                        name={`headDetail.arrear_head.${index}.gst_applicable`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.arrear_head.${index}.gst_applicable`,e.target.checked ? "Yes" : "No")
                                            // data.arrear_head[item.id].gst_applicable = e.target.checked ? "Yes" : "No";
                                            // handleChange(data)
                                        }}
                                    />
                                </td>
                                <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="Exemption Not Applicable"
                                        className="m-3"
                                        initialchecked={
                                            item?.excemption === "Y" ? true : false
                                        }
                                        name={`headDetail.arrear_head.${index}.excemption`}
                                        onChange={(e) => {
                                            setFieldValue(`headDetail.arrear_head.${index}.excemption`,e.target.checked ? "Y" : "N")
                                            // data.arrear_head[item.id].excemption = e.target.checked ? "Y" : "N";
                                            // handleChange(data)
                                        }}
                                    />
                                </td>
                            </tr> : <></>}
                            {item?.invoice_head === "Current Period Interest" ? <tr className="border-b-2">
                                {/* <td className="px-2 py-1">
                                    <CheckboxFM
                                        text="Noc"
                                        className="m-3"
                                        initialchecked={
                                            item?.gst_applicable === "Yes" ? true : false
                                        }
                                        onChange={(e) => {
                                        }}
                                    />
                                </td> */}
                                <td className="px-2 py-1 text-center" colSpan={2}>
                                    <FormControl className="m-3" >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name={`headDetail.arrear_head.${index}.servicegood`}
                                            value={item?.servicegood || 'Y'}
                                            onChange={(e) => {
                                                setFieldValue(`headDetail.arrear_head.${index}.servicegood`,e.target.value)
                                                //item.servicegood = e.target.value;
                                                //handleChange(data)
                                            }}
                                        >
                                            <FormControlLabel value="Y" className="text-sm" control={<Radio sx={{ color: "#CF001C", '&.Mui-checked': { color: "#CF001C", }, }} />} label="Service" />
                                            <FormControlLabel value="N" className="text-sm" control={<Radio sx={{ color: "#CF001C", '&.Mui-checked': { color: "#CF001C", }, }} />} label="Good" />
                                        </RadioGroup>
                                    </FormControl>
                                </td>

                            </tr> : <></>}
                        </tbody>
                    </table>
                    {/* <div className="w-full p-5">col 1</div> */}
                </div>

            )) : <></>}

        </div>
    )
}
InvoiceHeadSettingGrid.propTypes = {
    data: PropTypes.array.isRequired,
};

export default InvoiceHeadSettingGrid;
