import { notify } from '../../../utils/notification';
import apiEndpoints from '../../api';
import axiosInstance from '../../axiosInstance'
import axiosInstance2 from '../../axiosInstance2'
import {logOut} from '../../../utils/helpers/logOut'
import { errorMsg } from '../../../consts/responseMessages';

export async function getBookingListReq(action) {

    const BODY= {
        "society_id": action.data.societyId,
        ...(action.data.searchText && {"filter_text": action.data.searchText}),
        ...(action.data.facilityId && {"filter_facility": action.data.facilityId}),
        ...(action.data.status && { "filter_f_status": action.data.status}),
        ...(action.data.pstatus && {"filter_p_status": action.data.pstatus}),
        ...(action.data.startDate && {"filter_from_date": action.data.startDate}),
        ...(action.data.endDate && {"filter_to_date": action.data.endDate}),
        ...(action.data.year && {"filter_year": action.data.year}),
        ...(action.data.month && {"filter_month": action.data.month}),
        ...(action.data.memberId && {"filter_member_id": action.data.memberId}),
        "page": action.data.page,
        "limit": action.data.pageLimit
      }

    return axiosInstance.post(apiEndpoints.GET_BOOKING_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getBookingStatusListReq(action) {
    return axiosInstance.get(apiEndpoints.GET_BOOKING_STATUS_LIST_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getBookingDetailsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_BOOKING_DETAILS_API}?id=${action.data.bookingId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function makeBookingAdjustmentReq(action) {

    const BODY= {
        "booking_id": action.data.bookingId,
        "amount": action.data.amount,
        "payment_date": action.data.date,
        "remark": action.data.remarks
      }

    return axiosInstance.post(apiEndpoints.MAKE_BOOKING_ADJUSTMENT_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function getFacilityReportReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_FACILITY_REPORT_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getFacilityAuditLogsReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_ALL_FACILITY_AUDIT_LOG_API}?society_id=${action.data.societyId}${action.data.facilityId ? `&facility_id=${action.data.facilityId}&is_web_user=1` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function changeBookingStatusReq(action) {

    const BODY= {
        "id": action.data.bookingId,
        "status": action.data.status,
        "reason": action.data.reason,
      }

    return axiosInstance.put(apiEndpoints.CHANGE_BOOKING_STATUS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function downloadFacilityReportExcelSheetReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_FACILITY_REPORT_EXCEL_API}?society_id=${action.data?.societyId}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function getBookingGLMappingListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_BOOKING_GL_MAPPING_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}


export async function getBookingBankListReq(action) {
    return axiosInstance.get(`${apiEndpoints.GET_BOOKING_BANK_ACCOUNT_LIST_API}?society_id=${action.data.societyId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}




export async function getBookingInvoiceReq(action) {
    const BODY={
        'pageid': action.data?.societyId,
        'invid': action?.data?.invoiceId,
        'payid': action?.data?.payid,
    }

    return axiosInstance2.post(apiEndpoints.GET_BOOKING_INVOICE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function makeBookingPaymentReq(action) {
    const BODY={
        'pageid': action.data?.societyId,
        'invoicedetail': action.data?.invoicedetail,
        'invid': action?.data?.invid,
        'amount': action?.data?.amount,
        // 'chq_society_bank': action?.data?.chq_society_bank,
        'bankid': action?.data?.bankid,
        'date': action?.data?.date,
        'transaction_number': action?.data?.transactionNumber,
        'cheque_number': action?.data?.cheque_number,
        'bankname': action?.data?.bankname,
        'branchname': action?.data?.branchname,
        'paymentdate': action?.data?.paymentdate,
        'cleardate': action?.data?.cleardate,
        'remark': action?.data?.remark,
        'modeofpayment': action?.data?.modeofpayment,
        'payid' : action?.data?.payid || null,
        'resonType' : action?.data?.resonType || null
    }

    // let data = new FormData();
    // data.append('pageid', action.data?.societyId);
    // data.append('invid', action?.data?.invid);
    // data.append('amount', action?.data?.amount);
    // data.append('chq_society_bank', action?.data?.chq_society_bank);
    // data.append('bankid', action?.data?.bankid);
    // data.append('date', action?.data?.date);
    // data.append('transaction_number', action?.data?.transaction_number);
    // data.append('cheque_number', action?.data?.cheque_number);
    // data.append('bankname', action?.data?.bankname);
    // data.append('branchname', action?.data?.branchname);
    // data.append('paymentdate', action?.data?.paymentdate);
    // data.append('cleardate', action?.data?.cleardate);
    // data.append('remark', action?.data?.remark);
    // data.append('modeofpayment', action?.data?.modeofpayment);

    return axiosInstance2.post(apiEndpoints.MAKE_BOOKING_PAYMENT_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}