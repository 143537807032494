import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
// import { getChequeDepositSlipReport } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import parse from "html-react-parser";
import { getInvoiceReport } from "../../../../redux/actions/ReportActions/FinanceReportActions";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

// export const headCells = [
//   { id: "invoiceNo", label: "Invoice No", sort: false, width: "100px" },
//   { id: "invoiceDate", label: "Invoice Date", sort: true, width: "160px" },
//   { id: "flatNo", label: "Flat No", sort: true, width: "100px" },
//   { id: "memberName", label: "Member Name", sort: true, width: "100px" },
//   {
//     id: "gstNo",
//     label: "GST No",
//     sort: true,
//     width: "130px",
//   },
//   { id: "penalty", label: "Penalty", sort: true, width: "120px" },
//   { id: "testing", label: "Testing", sort: true, width: "100px" },
//   {
//     id: "creditCause",
//     label: "Test credit cause",
//     sort: false,
//     width: "100px",
//   },

//   {
//     id: "subTotal",
//     label: "SubTotal",
//     sort: false,
//     width: "100px",
//   },
//   {
//     id: "principalArrear",
//     label: "Principal Arrear	",
//     sort: false,
//     width: "100px",
//   },
//   {
//     id: "interestArrear",
//     label: "Interest Arrear	",
//     sort: false,
//     width: "100px",
//   },
//   {
//     id: "interest",
//     label: "Interest",
//     sort: false,
//     width: "100px",
//   },
//   {
//     id: "grandTotal",
//     label: "Grand Total	",
//     sort: false,
//     width: "100px",
//   },
//   {
//     id: "payment",
//     label: "Payment",
//     sort: false,
//     width: "100px",
//   },
//   {
//     id: "outstanding",
//     label: "Outstanding",
//     sort: false,
//     width: "100px",
//   },
// ];

const PrintInoviceReport = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [headCells, setHeadCells] = useState([]);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { invoiceReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );

  const getTableData = () => {
    dispatch(
      getInvoiceReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        invoice: searchParams.get("invoice") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
      })
    );
  };

  useEffect(() => {
    getTableData();

    const afterprint = (e) => {
      e.preventDefault();
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  useEffect(() => {
    if (!loading && invoiceReport?.data?.length > 0) {
      setTimeout(function () {
        window.print();
      }, 2000);
    }
  }, [dispatch]);

  useEffect(() => {
    if (invoiceReport?.data?.length)
      setHeadCells(
        Object.entries(invoiceReport.data.slice(-1)[0])
          .filter((item) => item[1] !== 0)
          ?.map((item) => ({
            label: item[0],
            id: item[0].replaceAll(" ", "_"),
            sort: true,
            width: "130px",
          }))
      );
  }, [invoiceReport]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        // <>{parse(chequeDepositSlipReport)}</>
        <div className="m-1">
          <table className="table-auto w-full mx-auto border-collapse border border-black">
            <caption className="caption-top  mt-4 text-center">
              <h1
                style={{
                  textDecoration: "underline",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {loginData?.default_community?.community_name}
              </h1>
              <div className="text-[11px] ">
                (REG No.{loginData?.default_community?.regn_no} Date{" "}
                {moment(
                  new Date(loginData?.default_community?.date_of_regn)
                )?.format("DD-MM-YYYY")}
                )
              </div>
              <div className="text-[13px] ">
                {loginData?.default_community?.office_address}
              </div>
              <div className="font-extrabold text-lg mb-3  text-start">
                Invoice Report From:{" "}
                {searchParams.get("startDate")
                  ? moment(new Date(searchParams.get("startDate")))?.format(
                      "DD-MM-YYYY"
                    )
                  : "-"}{" "}
                To:{" "}
                {searchParams.get("endDate")
                  ? moment(new Date(searchParams.get("endDate")))?.format(
                      "DD-MM-YYYY"
                    )
                  : "-"}
              </div>
            </caption>
            <thead>
              <tr>
                {headCells.length &&
                  headCells?.map((item) => (
                    <td
                      className="text-[11px] p-1 font-semibold border border-black"
                      key={item?.id}
                      style={{ minWidth: item.width || "fit-content" }}
                    >
                      {item?.label}
                    </td>
                  ))}
              </tr>
            </thead>
            <tbody>
              {invoiceReport?.data?.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {headCells.length && (
                    <tr className="text-[#222] text-[11px]">
                      {headCells?.map((item, headIndex) => {
                        return (
                          <td
                            className={`${
                              rowIndex + 1 == invoiceReport?.data?.length
                                ? " !font-bold text-sm"
                                : ""
                            } p-1 border border-black align-top`}
                          >
                            {/* {(index + 1)?.toString()?.padStart(2, 0)} */}
                            {row[item?.label]}
                          </td>
                        );
                      })}
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {!invoiceReport?.data?.length && !loading && (
            <div className="text-[11px] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrintInoviceReport;
