import * as types from "../index.types"

export function getDebitNoteInvoiceReport(data) {
    return {
        type: types.GET_DEBIT_NOTE_INVOICE_REPORTS,
        data
    }
}


export function getInvoiceReport(data) {
    return {
        type: types.GET_INVOICE_REPORTS,
        data
    }
}


export function getReceiptReport(data) {
    return {
        type: types.GET_RECEIPT_REPORTS,
        data
    }
}
export function downloadReceiptReport(data) {
    return {
        type: types.DOWNLOAD_RECEIPT_REPORTS,
        data
    }
}


export function getSupplementaryBillReport(data) {
    return {
        type: types.GET_SUPPLEMNTARY_BILL_REPORTS,
        data
    }
}

export function getDebitNoteReceiptReport(data) {
    return {
        type: types.GET_DEBIT_NOTE_RECEIPT_REPORTS,
        data
    }
}
export function downloadDebitNoteReceiptReport(data) {
    return {
        type: types.DOWNLOAD_DEBIT_NOTE_RECEIPT_REPORTS,
        data
    }
}


export function getDeletedInvoiceReport(data) {
    return {
        type: types.GET_DELETED_INVOICE_REPORTS,
        data
    }
}


export function getDeletedDebitNoteReport(data) {
    return {
        type: types.GET_DELETED_DEBIT_NOTE_REPORTS,
        data
    }
}
export function downloadDeletedDebitNoteReport(data) {
    return {
        type: types.DOWNLOAD_DELETED_DEBIT_NOTE_REPORTS,
        data
    }
}

export function getInvoiceInterestReport(data) {
    return {
        type: types.GET_INVOICE_INTEREST_REPORTS,
        data
    }
}
export function getPrevInvoiceInterestReport(data) {
    return {
        type: types.GET_PREV_INVOICE_INTEREST_REPORTS,
        data
    }
}

export function getInterestCalculationReport(data) {
    return {
        type: types.GET_INTEREST_CALCULATION_REPORTS,
        data
    }
}

export function getReconciliationReport(data) {
    return {
        type: types.GET_RECONCILIATION_REPORTS,
        data
    }
}

export function getDebitNoteInterestCalculationReport(data) {
    return {
        type: types.GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS,
        data
    }
}
export function downloadDebitNoteInterestCalculationReport(data) {
    return {
        type: types.DOWNLOAD_DEBITNOTE_INTEREST_CALCULATION_REPORTS,
        data
    }
}