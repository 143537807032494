import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance from '../../../axiosInstance'
import axiosInstance2 from '../../../axiosInstance2'
import {logOut} from '../../../../utils/helpers/logOut'
import getBrowserDetails from '../../../../utils/helpers/getBrowserDetails'
import { errorMsg } from '../../../../consts/responseMessages';

export async function downloadExcelFormatReq(action) {

    return axiosInstance.get(`${apiEndpoints.DOWNLOAD_EXCEL_FORMAT_API}?flag=${action.data.flag}`, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if(errors.response?.data.statusCode === 501){
                notify('warning', errors.response.data.message);
            }
            return errors

        })
}

export async function uploadStocietyStaffListReq(action) {

    var bodyFormData = new FormData();
    bodyFormData.append('society_id', action.data?.societyId);
    bodyFormData.append('browser_details', getBrowserDetails());
    bodyFormData.append('session_id', action.data?.sessionId);
    bodyFormData.append('ip', action.data?.ipAddress);
    bodyFormData.append('file', action.data.file);

    return axiosInstance.post(apiEndpoints.UPLOAD_STAFF_EXCEL_SHEET_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                if(errors.response?.data?.type === 'COLS_DATA_NOT_FOUND')
                    notify('error', "Columns name doesn't match");
                else
                    notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function uploadOldOccupantsListReq(action) {

    var bodyFormData = new FormData();
    bodyFormData.append('society_id', action.data?.societyId);
    bodyFormData.append('browser_details', getBrowserDetails());
    bodyFormData.append('session_id', action.data?.sessionId);
    bodyFormData.append('ip', action.data?.ipAddress);
    bodyFormData.append('file', action.data.file);

    return axiosInstance.post(apiEndpoints.UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                if(errors.response?.data?.type === 'COLS_DATA_NOT_FOUND')
                    notify('error', "Columns name doesn't match");
                // else
                //     notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function uploadNewOccupantsListReq(action) {

    var bodyFormData = new FormData();
    bodyFormData.append('society_id', action.data?.societyId);
    bodyFormData.append('browser_details', getBrowserDetails());
    bodyFormData.append('session_id', action.data?.sessionId);
    bodyFormData.append('ip', action.data?.ipAddress);
    bodyFormData.append('file', action.data.file);

    return axiosInstance.post(apiEndpoints.UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if(errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)){
                logOut()
            }else if (errors.response?.data.statusCode === 400) {
                if(errors.response?.data?.type === 'COLS_DATA_NOT_FOUND')
                    notify('error', "Columns name doesn't match");
                // else
                //     notify('error', errors.response.data.message);
            }else if (errors.response.data.statusCode === 401) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function uploadOccupantOpeningBalanceReq(action) {

    var bodyFormData = new FormData();
    bodyFormData.append('pageid', action.data?.societyId);
    bodyFormData.append('typeid', action.data?.invoiceType);
    bodyFormData.append('finyear', action.data?.finalYear);
    bodyFormData.append('to_date', action.data?.toDate);
    bodyFormData.append('excel', action.data.file);
    

    return axiosInstance2.post(apiEndpoints.UPLOAD_OCCUPANT_OPENING_BALANCE_SHEET_API, bodyFormData)
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if(!(typeof errors.response?.data.message === 'object' && !Array.isArray(errors.response?.data.message))){
                if (errors.response?.data.success === false) {
                    notify('error', errors.response.data.message);
                } else {
                    notify('warning', "Something went wrong");
                }
            }
            return errors

        })
}


export async function downloadOpeningBalanceExcelSampleReq(action) {
    var bodyFormData = new FormData();
    bodyFormData.append('act', action.data?.flag);
    

    return axiosInstance2.post(`${apiEndpoints.DOWNLOAD_OCCUPANT_OPENING_BALANCE_SHEET_SAMPLE_API}`, bodyFormData, {
        responseType: 'arraybuffer',
    })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}