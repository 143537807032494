import React from "react";
import SideNavbar from "../../components/SideNavbar/SideNavbar";
import Navbar from "../../components/Navbar/Navbar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Layout = ({ children }) => {
  return (
    // <div className="flex flex-col gap-[1.8rem] mx-w-[1500px] mx-auto">
    <div className="flex flex-col gap-[1.8rem] max-w-[1280px] w-full mx-auto">
      <Navbar />
      {/**/}
      <div
        // className="w-full  max-w-7xl "
        className="w-full  "
        style={{
          minHeight: "calc(100vh - 180px)",
        }}
      >
        <Header />
        <div className="flex mt-5">
          <div className="w-[300px] max-w-[280px] mr-[15px]">
            <SideNavbar />
          </div>
          {/* max-w-[982px] */}
          <div className="max-w-[982px] w-full">{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
