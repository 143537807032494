import React, { useEffect } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import {TextAreaFM } from "../../../../components/InputFields/TextArea";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { Form, Formik } from "formik";
import { EditCreditNoteValidation } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { getCreditNoteDetails, updateCreditNote } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction";
import Loader from "../../../../components/Loader/Loader";
import EditCreditDetailsBox from "./EditCreditDetailsBox";
import { CircularProgress } from "@mui/material";
import { getInvoiceTypeList,getCreditNoteSettingDetail } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { MANAGE_CREDIT_NOTE_ENDPOINT } from "../../../../Routing/routes";

const EditCreditNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {id} = useParams()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {loading, updateLoader, creditNoteDetails} = useSelector(state=>state.manageCreditNoteReducer)
  const { typeLists,settingList } = useSelector((state) => state.invoiceSettingReducer)
  const handleSubmit=(values, {resetForm})=>{
    if(creditNoteDetails)
      dispatch(updateCreditNote({
        ...values, 
        creditNoteId: id, 
        societyId: loginData?.default_community?.community_id,
        loginType:  loginData?.user_type,
        onSuccessCallback: ()=>{
          navigate(MANAGE_CREDIT_NOTE_ENDPOINT)
        }
      }))
  }


  useEffect(()=>{
    const societyId= loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({societyId}))
    dispatch(getCreditNoteSettingDetail({ societyId : loginData?.default_community?.community_id, invoiceType: 1}))
    dispatch(getCreditNoteDetails({creditNoteId: id, societyId}))
  }, [])
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
      <Formik
          initialValues={{  
              wingId: creditNoteDetails?.creditnote?.wingId || '',
              flatId: creditNoteDetails?.creditnote?.flatId || '',
              memberId: creditNoteDetails?.creditnote?.userId || '',

              memberGlCode: creditNoteDetails?.creditnote?.credit_code || '',
              loginId: creditNoteDetails?.creditnote?.loginId || '',
              journalid: creditNoteDetails?.creditnote?.journal_id || '',
              registerid: creditNoteDetails?.creditnote?.registerid || '',
              debitorType: creditNoteDetails?.creditnote?.userType || '',
              date: creditNoteDetails?.creditnote?.date || '',
              voucher: creditNoteDetails?.creditnote?.Hard_copy_voucher ||  '',
              remarks: creditNoteDetails?.creditnote?.remarks ||  '',
              sendEmail: 'Y',
              sendSMS: 'Y',
              details: creditNoteDetails?.creditnoteDetail?.map((item)=>({
                id: item.id || 0,
                tariffHeaderId: item.cause || '',
                debitAccountId: item.debit_code || '',
                hsnCode: item.hsn_code || "",
                serviceGood: item.service || 'N',
                amount: item.amount || '',
                gstApplicable: item.gstapplicable || 'N'
              }))
          }}
          enableReinitialize
          validationSchema={EditCreditNoteValidation}
          onSubmit={handleSubmit}
        >
          {
            ({values, setFieldValue})=>(
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Edit Credit Note">
                    <div className="flex gap-2">
                      <ButtonG
                        label="Cancel"
                        onClick={()=>{navigate(-1)}}
                        className="h-8"
                        type="button"
                      />
                      <Button
                        label={
                          <span className="inline-block w-16">
                            {
                            updateLoader ?
                              <CircularProgress sx={{color: "white"}} size={17}/>
                              :
                              <>Save</>
                            }
                          </span>
                          }
                        className="h-8 px-5"
                        type="submit"
                      />
                    </div>
                  </ListHeader>
                </div>
                {
                    loading ?
                    <Loader/>
                    :
                    <>
                        <div className="flex gap-4 p-2">
                        <div className="flex flex-row justify-between w-full gap-7">
                            <div className="flex flex-col w-1/2">

                            <div className="flex flex-row justify-between">
                                <span className="mt-2 text-sm">Credit Note Date	</span>
                                <div className="flex gap-3">
                                <DatePickerComponentFM
                                    className="w-56 h-9" 
                                    name={`date`}
                                    onDateChange={(selectedDate) => {
                                    setFieldValue(`date`, moment(selectedDate).format('YYYY-MM-DD'))
                                    }}
                                    minDate={creditNoteDetails?.creditnote?.date ? new Date(creditNoteDetails?.creditnote?.date) : new Date()}
                                    defaultValue={values.date ? new Date(values.date) : null}
                                />
                                </div>
                            </div>
                            <TextInputFM
                                label="Hard Copy Voucher"
                                placeholder="Enter Voucher"
                                className="w-56"
                                name='voucher'
                            />
                            <div className="flex mb-4 text-sm">
                                <div className="flex-shrink-0 w-1/2 mr-3">Member/vendor Details</div>
                                <div >
                                {creditNoteDetails?.creditnote?.member}
                                </div>
                            </div>

                            <div className="w-full">
                              <TextAreaFM
                                label="Remarks"
                                placeholder="Enter Remarks"
                                className={"w-56 h-[87px]"}
                                maxLength={300}
                                name='remarks'
                                />
                            </div>

                            <div className="flex items-center justify-between gap-3 mb-4">
                                <div className="text-sm">Send SMS And Email</div>
                                <div className="py-2 text-sm">
                                <Checkbox
                                    text={"Email"}
                                    className={"w-28"}
                                    initialchecked={values.sendEmail === 'Y'}
                                    onChange={(e) => {setFieldValue('sendEmail', e.target.checked ? 'Y' : 'N' )}}
                                />
                                <Checkbox
                                    text={"SMS"}
                                    className={"w-28"}
                                    initialchecked={values.sendSMS === 'Y'}
                                    onChange={(e) => {setFieldValue('sendSMS', e.target.checked ? 'Y' : 'N' )}}
                                />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <EditCreditDetailsBox 
                            values={values}
                            creditNoteCause={settingList?.trafic_detail || []}
                            setFieldValue={setFieldValue}
                        />
                    </>
                }
              </Form>
            )
          }
          </Formik>
      </div>
    </Layout>
  );
};

export default EditCreditNote;
