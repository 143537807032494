import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UtilityReadingValidation } from "../../../validationSchema/setupRecordsSchema/buildingSchema";
import { updateUtilityReadingsDetails } from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import Loader from "../../../components/Loader/Loader";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import Button from "../../../components/Button/Button";
import { CircularProgress } from "@mui/material";
import { monthOptions } from "../../../consts/DropDownOptions";

const UtilityReadingTable = ({
  wingId,
  societyId,
  month,
  year,
  filter = {},
  setSaveClick = () => {},
}) => {
  const { loading, updateLoader, utilityReadings } = useSelector(
    (state) => state.wingsReducer
  );
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const { headList } = useSelector((state) => state.headListReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const formRef = useRef();

  useEffect(() => {
    setSaveClick(() => () => {
      formRef.current?.submitForm();
    });
  }, [formRef]);

  return (
    <div className="table-container w-full ">
      <Formik
        enableReinitialize
        initialValues={{
          utilityReading: utilityReadings?.reading
            ? utilityReadings?.reading
                ?.filter((item) => {
                  return (
                    item.invoice_type ==
                      (filter.invoiceId || typeLists?.[0]?.id) &&
                    (filter?.headId ? item.head_id == filter?.headId : true)
                  );
                })
                ?.map((item) => ({
                  readingId: item?.reading_id,
                  flatNo: item?.flat_no,
                  wingNo: item?.wing_no,
                  month: item?.month,
                  year: item?.year,
                  head: item?.head,
                  fixedCharge: item?.fixed_charge || "",
                  lastMonthReading: item?.last_reading || "",
                  currentMonthReading: item?.current_reading || "",
                  rate: item?.rate || "",
                  units: item?.current_reading - item?.last_reading || "",
                  invoiceType: item?.invoice_type,
                }))
            : [],
        }}
        onSubmit={(values) =>
          dispatch(
            updateUtilityReadingsDetails({
              ...values,
              utilityReading: values.utilityReading.map((item) => {
                return {
                  ...item,
                  units: item?.currentMonthReading - item?.lastMonthReading,
                };
              }),
              ipAddress,
              wingId,
              societyId,
              month,
              year,
              sessionId: loginData?.session_id,
            })
          )
        }
        validationSchema={UtilityReadingValidation}
        innerRef={(f) => (formRef.current = f)}
      >
        {({ values, dirty }) => {
          return (
            <Form>
              <div className="overflow-auto">
                <table className="table-auto w-full">
                  <thead>
                    <tr className="border-b border-t text-xs text-[#AAAAAA] text-left">
                      <th className="min-w-[40px] px-1 py-2">S.No</th>
                      <th className="min-w-[60px] px-2 py-2">Wing</th>
                      <th className="min-w-[90px] px-2 py-2">Flat Number</th>
                      <th className="min-w-[90px] px-2 py-2">Invoice Type</th>
                      <th className="min-w-[90px] px-2 py-2">Day Book</th>
                      <th className="min-w-[140px] px-2 py-2">Head Name</th>
                      <th className="min-w-[100px] px-2 py-2">Month </th>
                      <th className="min-w-[100px] px-2 py-2">Year</th>

                      <th className="min-w-[140px] px-2 py-2">Fixed Charges</th>
                      <th className="min-w-[160px] px-2 py-2">
                        Last Month Reading
                      </th>
                      <th className="min-w-[160px] px-2 py-2">
                        Current Month Reading
                      </th>
                      <th className="min-w-[160px] px-2 py-2">Rate</th>
                      <th className="min-w-[90px] px-2 py-2">Units</th>
                    </tr>
                  </thead>
                  <FieldArray
                    name="flats"
                    render={(arrayHelpers) => (
                      <>
                        <tbody>
                          <tr className="h-4"></tr>
                          {loading ? (
                            <tr className="text-center">
                              <td
                                colSpan={10}
                                className="text-center py-5 italic text-[#AAAAAA]"
                              >
                                <Loader />
                              </td>
                            </tr>
                          ) : values?.utilityReading?.length ? (
                            values?.utilityReading?.map((data, index) => (
                              <tr key={index} className="text-xs">
                                <td className="p-2 align-top">
                                  {" "}
                                  {(index + 1)?.toString()?.padStart(2, 0)}
                                </td>
                                <td className=" align-top p-2">
                                  {data.wingNo}
                                </td>
                                <td className=" align-top p-2">
                                  {data.flatNo}
                                </td>
                                <td className=" align-top p-2">
                                  {typeLists?.length > 0 && data.invoiceType
                                    ? typeLists.find(
                                        (item) => item.id == data.invoiceType
                                      )?.invoice_title
                                    : "-"}
                                </td>
                                <td className=" align-top p-2">
                                  {typeLists?.length > 0 && data.invoiceType
                                    ? typeLists.find(
                                        (item) => item.id == data.invoiceType
                                      )?.daybook
                                    : "-"}
                                </td>

                                <td className=" align-top p-2">{data.head}</td>
                                <td className=" align-top p-2">
                                  {monthOptions[data.month - 1]?.label}
                                </td>
                                <td className="align-top p-2">
                                  {data.year ? data.year : "-"}
                                </td>
                                <td className="px-2  align-top ">
                                  <TextInputFM
                                    label=""
                                    placeholder="Enter"
                                    value={Number(data.fixedCharge).toFixed(2)}
                                    className="text-xs h-9"
                                    name={`utilityReading.${index}.fixedCharge`}
                                    type="decimal"
                                  />
                                </td>
                                <td className=" px-2  align-top">
                                  <TextInputFM
                                    label=""
                                    placeholder="Enter"
                                    value={Number(
                                      data.lastMonthReading
                                    ).toFixed(2)}
                                    className="text-xs h-9"
                                    name={`utilityReading.${index}.lastMonthReading`}
                                    type="number"
                                  />
                                </td>
                                <td className=" px-2  align-top">
                                  <TextInputFM
                                    label=""
                                    placeholder="Enter"
                                    value={Number(
                                      data.currentMonthReading
                                    ).toFixed(2)}
                                    className="text-xs h-9"
                                    name={`utilityReading.${index}.currentMonthReading`}
                                    type="number"
                                  />
                                </td>
                                <td className=" px-2  align-top">
                                  <TextInputFM
                                    label=""
                                    placeholder="Enter"
                                    value={Number(data.rate).toFixed(2)}
                                    className="text-xs h-9"
                                    name={`utilityReading.${index}.rate`}
                                    type="number"
                                  />
                                </td>
                                <td className=" p-2 font-semibold align-top">
                                  {Number(
                                    Number(data.currentMonthReading) -
                                      Number(data.lastMonthReading)
                                  ).toFixed(2)}
                                  {/* <TextInputFM
                                  label=""
                                  placeholder="Enter"
                                  value={
                                    data.currentMonthReading -
                                    data.lastMonthReading
                                  }
                                  className="text-xs h-9"
                                  name={`utilityReading.${index}.units`}
                                  type="number"
                                  disabled={true}
                                /> */}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="text-center">
                              <td
                                colSpan={10}
                                className="text-center py-5 italic text-[#AAAAAA]"
                              >
                                No records
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </>
                    )}
                  ></FieldArray>
                </table>
              </div>
              {/* {!!utilityReadings?.reading?.length && (
                <div className="text-center">
                  <Button
                    label={
                      <span className="w-8 inline-block">
                        {updateLoader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Save"
                        )}
                      </span>
                    }
                    type="submit"
                    disabled={loading || updateLoader || !dirty}
                  />
                </div>
              )} */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UtilityReadingTable;
