import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import Button from "../../components/Button/Button";
import ButtonG from "../../components/Button/ButtonG";
import Layout from "../Layout/Layout";
import ListHeader from "../../components/ListHeader/ListHeader";
import { UploadEx } from "../../assets";
import Dropdown2 from "../../components/Dropdown/dropdown2";
import { financialYears } from "../../consts/DropDownOptions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  clearBulkUploadError,
  downloadOpeningBalanceExcelSample,
  uploadOccupantsOpeningbalanceSheet,
} from "../../redux/actions/SetupRecordsActions/BulkUploadActions/BulkUploadAction";
import { notify } from "../../utils/notification";
import { downloadExcel } from "../../utils/helpers/downloadMedia";
import { useNavigate } from "react-router-dom";

const BulkUploadOpeningBalance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [year, setYear] = useState();
  const inputFile = useRef(null);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { uploadLoader, errorMsg, error } = useSelector(
    (state) => state.bulkUploadReducer
  );
  // const { typeLists } = useSelector(
  //   (state) => state.invoiceSettingReducer
  // );
  const [invoiceType, setInvoicetype] = useState(1);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onUpload = () => {
    if (!year) {
      notify("warning", "Please select the year");
    } else {
      let data = {
        societyId: loginData?.default_community?.community_id,
        invoiceType: invoiceType,
        file,
        toDate: "",
        finalYear: year,
        onRequestEndCallback: () => {
          setFile(null);
          inputFile.current.value = "";
          inputFile.current.type = "text";
          inputFile.current.type = "file";
        },
      };
      dispatch(uploadOccupantsOpeningbalanceSheet(data));
    }
  };

  const handleDownloadSampleAndFormatExcel = (flag) => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: `Opening Balance Excel ${flag}.xls` });
    };
    const data = {
      flag,
      onSucessCallback,
    };
    dispatch(downloadOpeningBalanceExcelSample(data));
  };
  // useEffect(() => {
  //   dispatch(getInvoiceTypeList({
  //     societyId: loginData?.default_community?.community_id,
  //   }))
  // }, []);

  useEffect(() => {
    return () => dispatch(clearBulkUploadError());
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Opening Balance Upload">
            {/* <Dropdown2
              options={
                typeLists
                  ? typeLists?.map(
                    (item) => ({
                      label: item?.invoice_title,
                      value: item?.id,
                    })
                  )
                  : []
              }
              placeholder="Invoice type"
              className="text-[11px] "
              width="224px"
              height="36px"
              name="invoice_type"
              onSelect={(selectedItem) => {
                setInvoicetype(selectedItem.value);
              }}
              value={
                typeLists && {
                  value: invoiceType,
                  label: typeLists ? typeLists?.find((item) => item?.id === parseInt(invoiceType))?.invoice_title : "",
                }
              }
            /> */}
            <Dropdown2
              options={financialYears}
              width="150px"
              height="36px"
              name={"onBehalfOf"}
              placeholder="Select Year"
              onSelect={(selectedItem) => {
                setYear(selectedItem.value);
              }}
            />
            <div className="border-r border-[#CCC]"></div>
            <ButtonG
              label="Cancel"
              onClick={() => { navigate(-1) }}
              className="h-8"
              type="button"
            />
            <Button
              label={
                <span className="inline-block w-12 text-enter">
                  {uploadLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Upload"
                  )}
                </span>
              }
              className="h-8 px-4 disabled:opacity-50"
              disabled={!file}
              onClick={onUpload}
            />
          </ListHeader>
        </div>
        <div className="w-full mt-4">
          <p className="mt-6 mb-4 text-sm font-semibold text-center">
            Note: Please do not leave any field empty except annual property
            tax, if it is not applied
          </p>
          <div className="">
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              className="w-[60%] ml-48 flex justify-center border-dashed border-2 border-[#CCC] rounded-md text-center bg-[#F9FAFC] cursor-pointer"
            >
              <label
                htmlFor="fileInput"
                className="block w-full cursor-pointer p-9"
              >
                <div className="flex flex-col justify-center">
                  <div className="flex justify-center">
                    <UploadEx className="w-6" />
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    className="hidden"
                    accept={
                      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    }
                    onChange={handleFileChange}
                    ref={inputFile}
                  />
                  <label
                    htmlFor="fileInput"
                    className="block text-sm font-semibold"
                  >
                    Upload .xls file
                  </label>
                  <p className="text-[#AAAAAA] text-[13px]">or drag and drop</p>
                </div>
              </label>
            </div>
            <div className="w-[60%] ml-48 my-3 text-sm h-4 font-semibol text-nowrap text-[#AAAAAA] text-ellipsis overflow-hidden ">
              {file && `File Name: ${file?.name}`}
            </div>
            <div className="w-[60%] ml-48 flex justify-between text-red-650 cursor-pointer text-xs font-semibold">
              <div
                onClick={() => {
                  handleDownloadSampleAndFormatExcel("sample");
                }}
              >
                Download Sample
              </div>
              <div
                onClick={() => {
                  handleDownloadSampleAndFormatExcel("format");
                }}
              >
                Download Format
              </div>
            </div>
            {errorMsg && !uploadLoader && (
              <div className="w-[60%] mt-4 ml-48 text-red-650 cursor-pointer text-wrap break-all text-xs font-semibold text-justify">
                Error:
                <br />
                {errorMsg}
              </div>
            )}
            {error && !uploadLoader && (
              <div className="w-[60%] mt-4 ml-48 text-red-650 cursor-pointer text-wrap break-all text-xs font-semibold text-justify">
                <br />
                {Object.entries(error)?.map((item) => (
                  <div>
                    {/* {item[1]?.includes('Opening') ? '' : `Row ${item[0]}: `}   */}
                    {item[1]}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BulkUploadOpeningBalance;
