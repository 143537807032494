import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import { ErrorMessage } from "formik";
import { CalenderIcon } from "../../assets";
import moment from "moment";
import { validateDate } from "../../utils/helpers/universalFunctions";

export const DatePickerComponent = ({ className, onDateChange, defaultValue, minDate, maxDate, readOnly=false}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const startDateRef = useRef(null)

  const handleStartDateChange = (date) => {
    setStartDate(date)
    startDateRef?.current?.setState(prev => ({ ...prev, open: false }))
    if(onDateChange){
      onDateChange(date.toISOString())
    }
  };


  useEffect(()=>{
    if(defaultValue){
      setStartDate(defaultValue)
    }
  }, [defaultValue])

  return (
        <div className={`${className}  flex justify-between items-center border border-[#CCC] rounded-md px-2 bg-[#F9FAFC]`}>
          <div className="relative cursor-text" onClick={()=>{startDateRef?.current?.setOpen(true)}}>
            <div className="absolute text-xs font-semibold py-1 bg-[#F9FAFC]">
                {
                  defaultValue ?
                  <span>{moment(new Date(defaultValue)).format('DD/MM/YYYY')}</span>
                  :
                  <span className="font-semibold text-[#AAAAAA] italic">{'Select a date'}</span>
                }
            </div>
            <DatePicker
              className="outline-none text-xs border-none w-full bg-[#F9FAFC] font-semibold"
              placeholderText={'Select a date'}
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              maxDate={maxDate}
              readOnly={readOnly}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              ref={startDateRef}
              scrollableYearDropdown
            showYearDropdown
            dateFormatCalendar="MMMM"
            // yearDropdownItemNumber={15}
            />
          </div>
          <CalenderIcon />
        </div>
  );
};

export const DatePickerComponentFM = ({showYearPicker=false,unavailableDate=false, dateFormat='DD/MM/YYYY', name, className, onDateChange, defaultValue, minDate, maxDate, readOnly=false,placeHolder="Select a date"}) => {
  const [startDate, setStartDate] = useState(validateDate(minDate) ? minDate : (maxDate ? maxDate : new Date())); 
  const startDateRef = useRef(null)
  const handleStartDateChange = (date) => {
    setStartDate(date)
    startDateRef?.current?.setState(prev => ({ ...prev, open: false }))
    if(onDateChange){
      onDateChange(date.toISOString())
    }
  };

  useEffect(()=>{
    if(defaultValue && defaultValue instanceof Date && !isNaN(defaultValue.valueOf())){
      setStartDate(defaultValue)
    }
  }, [defaultValue])

  
  useEffect(()=>{
    if(validateDate(minDate) && !defaultValue){
      setStartDate(minDate)
    }
  }, [minDate])

  return (
    <div className='mb-2'>
      <div className=" h-[35px]">
        <div className={`${className}  flex justify-between items-center border border-[#CCC] rounded-md px-2 bg-[#F9FAFC]`} onClick={()=>{if(!readOnly){startDateRef?.current?.setOpen(true)}}}>
          <div className="relative cursor-text">
            <div className="absolute text-xs font-semibold top-1/2 transform -translate-y-1/2 py-1 bg-[#F9FAFC]">
                {
                  defaultValue && defaultValue instanceof Date && !isNaN(defaultValue.valueOf()) ?
                  <span>{moment(new Date(defaultValue)).format(dateFormat)}</span>
                  :
                  <span className="font-semibold text-[#AAAAAA] italic">{placeHolder}</span>
                }
            </div>
            <DatePicker
              className="outline-none text-xs border-none w-full bg-[#F9FAFC] font-semibold"
              placeholderText={placeHolder}
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              selectsStart
              {...(showYearPicker && {showYearPicker})}
              startDate={startDate}
              minDate={minDate}
              maxDate={maxDate}
              readOnly={readOnly}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              excludeDates={unavailableDate}
              ref={startDateRef}
              scrollableYearDropdown
              showYearDropdown
              dateFormatCalendar="MMMM"
              // yearDropdownItemNumber={15}
            />
          </div>
          <CalenderIcon />
        </div>
      </div>
      <div className="h-auto text-xs italic text-red-500">
        <ErrorMessage name={name}/>
      </div>
    </div>

  );
};

