import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import FilterTextField from "../../../../components/InputFields/FilterTextFields";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { TextInput } from "../../../../components/InputFields/TextInput";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx, PrinterIcon } from "../../../../assets";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  INVOICE_INTEREST_REPORT_ENDPOINT,
  PREV_INVOICE_INTEREST_REPORT_ENDPOINT,
} from "../../../../Routing/routes";
import ManageDebitCurrentTable from "../../../../components/TableComponent/ManageDebitCurrentTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  downloadExcel,
  exportToCSV,
} from "../../../../utils/helpers/downloadMedia";

import ButtonG from "../../../../components/Button/ButtonG";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  getinvoiceInterestReport,
  getInvoiceInterestReport,
} from "../../../../redux/actions/ReportActions/FinanceReportActions";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import InvoiceInterestReportTable from "./InvoiceInterestReportTable";
import PrevInvoiceInterestReportTable from "./PrevInvoiceInterestReportTable";

const headCells = [
  { id: "sNo", label: "SNo.", sort: false, width: "40px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "160px" },
  { id: "memberDetails", label: "Member Details	", sort: true, width: "150px" },
  { id: "invoiceDate", label: "Invoice Date	", sort: true, width: "100px" },
  {
    id: "generationDate",
    label: "Generation Date",
    sort: true,
    width: "130px",
  },
  { id: "item", label: "Item", sort: false, width: "120px" },
  { id: "actualAmount", label: "Actual Amount", sort: true, width: "120px" },
  { id: "billAmount", label: "Bill Amount", sort: true, width: "120px" },
];
const invoice = [
  { label: "Debit Note", value: "Debit Note" },
  { label: "Supplementary", value: "Supplementary" },
];
const searchBy = [
  { label: "Invoice Date", value: "invoice_date" },
  { label: "Generation Date", value: "date" },
];
const debitorType = [
  { label: "All", value: "All" },
  { label: "Member", value: "Member" },
  { label: "Vendor", value: "Vendor" },
  { label: "Tenant", value: "Tenant" },
];
const gstNo = [
  { label: "B2B", value: "With GST No" },
  { label: "B2C", value: "WithOut GST No" },
];
const status = [
  { label: "All", value: "All" },
  { label: "Verified", value: "verified" },
  { label: "Unpaid", value: "pending" },
  { label: "Unverified", value: "unverified" },
  { label: "Rejected", value: "rejected" },
  { label: "Carried Forward", value: "carried_forward_status" },
  { label: "Carried Forward In Invoice", value: "carried_forward_in_invoice" },
  { label: "Adjust In Invoice", value: "adjust_in_invoice" },
  { label: "Excess Paid", value: "excess_paid" },
  { label: "Dues", value: "dues" },
];

const PrevInvoiceInterestReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);
  // const [headCells, setHeadCells] = useState([]);
  const [defaultFromValue, setDefaultFromValue] = useState("");
  const [defaultToValue, setDefaultToValue] = useState("");

  const { invoiceInterestReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleDownloadExcel = () => {
    if (invoiceInterestReport?.data?.length) {
      const data = "fasdfds".split("").map((item, index) => ({
        sNo: index + 1,
        invoiceNo: "HC-0037/2025-26 Revised",
        memberDetails: "Mayank Lakhera Wing No.Test - A101",
        invoiceDate: "09-03-2026	",
        generationDate: "22-04-2024	",
        amount: {
          amount: 1212,
          paid: 12,
          due: 1200,
        },
        status: "pending",
        useId: "QAdev4175@zipgrid.com	",
        userType: "Society",
      }));
      const object = {};
      Object.keys(data[0]).map(
        (item, index) =>
          (object[item] =
            index == 0
              ? "Total"
              : invoiceInterestReport?.total?.[item]
              ? invoiceInterestReport?.total?.[item]
              : "")
      );
      data.push(object);
      exportToCSV(data, "deleted-invoice-report");
    }
  };

  const handleInvoice = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("invoice");
    navigate({
      pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["invoice", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectGst = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("gstNo");
    navigate({
      pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["gstNo", selectedItem.value],
      ])}`,
    });
  };
  const handleSearch2 = (text) => {
    if (text && text !== searchParams.get("billNo")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("billNo");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billNo", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("billNo");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("memberName")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("memberName");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["memberName", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("memberName");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handleSearchBy = (text) => {
    if (text && text !== searchParams.get("searchBy")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("searchBy");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", text],
        ])}`,
      });
    }
  };

  const handleSelectDebitorType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("debitor_type");

    navigate({
      pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["debitor_type", selectedItem.value],
      ])}`,
    });
  };
  const handleAmountChange = (clear = false) => {
    if (clear) {
      searchParams.delete("to_amount");
      searchParams.delete("from_amount");
      setDefaultFromValue("");
      setDefaultToValue("");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    } else {
      searchParams.delete("to_amount");
      searchParams.delete("from_amount");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["from_amount", defaultFromValue],
          ["to_amount", defaultToValue],
        ])}`,
      });
    }
  };
  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    setDefaultFromValue("");
    setDefaultToValue("");
    navigate(
      `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}?searchBy=invoice_date&invoice=Debit Note`
    );
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }

    if (!searchParams.get("searchBy")) {
      navigate({
        pathname: `${PREV_INVOICE_INTEREST_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([["searchBy", "invoice_date"]])}`,
      });
    }
    // setHeadCells([]);
    setDefaultFromValue("");
    setDefaultToValue("");
  }, []);

  const getTableData = () => {
    dispatch(
      getInvoiceInterestReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        billNo: searchParams.get("billNo") || "",
        memberName: searchParams.get("memberName") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        previous: 1,
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Previous Invoice Interest Report">
            <div className="flex text-sm gap-3">
              {/* <button
                type="button"
                onClick={handlePrintData}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button> */}
              {/* <ButtonIco
                type="button"
                onClick={handleDownloadExcel}
                icon={DownloadExt}
                children="Download Excel"
              /> */}
              <Button
                label="Current Invoices"
                className="h-8 "
                onClick={handleNavigation(INVOICE_INTEREST_REPORT_ENDPOINT)}
              />
              <div className="border-r border-[#CCC]"></div>
              <Search
                height="8"
                onclick={handleSearch2}
                value={searchParams.get("billNo")}
                placeholder="Enter Invoice No"
              />
            </div>
          </ListHeader>
        </div>

        <div className="p-1 flex flex-row justify-between items-center gap-4 mt-1">
          <Search
            height="8"
            onclick={handleSearch}
            value={searchParams.get("memberName")}
            placeholder="Search Vendor/Member..."
          />
          {/* <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Bill Type</div>
            <Dropdown2
              options={invoice}
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectInvoiceType(selectedItem);
              }}
              value={
                searchParams.get("typeid") && {
                  value: searchParams.get("typeid"),
                  label: invoice
                    ? invoice?.find(
                        (item) =>
                          item?.id === parseInt(searchParams.get("typeid"))
                      )?.invoice_title
                    : "",
                }
              }
            />
          </div> */}
          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Search By</div>
            <Dropdown2
              options={searchBy}
              placeholder="Invoice Date"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSearchBy(selectedItem.value);
              }}
              value={
                searchParams.get("searchBy") && {
                  value: searchParams.get("searchBy"),
                  label: searchBy?.find(
                    (item) => item.value == searchParams.get("searchBy")
                  )?.label,
                }
              }
            />
            {/* <div className="border-r border-[#CCC] h-full"></div>

           
            <div className="border-r border-[#CCC] h-full"></div> */}

            <div className="flex justify-between gap-3 items-center">
              <RangeDatePicker
                className={"w-[190px] h-8"}
                defaultStartValue={
                  searchParams.get("startDate")
                    ? new Date(searchParams.get("startDate"))
                    : ""
                }
                defaultEndValue={
                  searchParams.get("endDate")
                    ? new Date(searchParams.get("endDate"))
                    : ""
                }
                onStartDateChange={handleStartDate}
                onEndDateChange={handleEndDate}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 p-2 ps-1">
          <Dropdown2
            options={
              wingsData?.map((item) => ({
                value: item?.wing_id,
                label: item?.number,
              })) || []
            }
            placeholder="Select Wing"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectWing(selectedItem);
            }}
            value={
              searchParams.get("wingId") && {
                value: searchParams.get("wingId"),
                label: wingsData?.find(
                  (item) =>
                    item.wing_id?.toString() === searchParams.get("wingId")
                )?.number,
              }
            }
          />
          <Dropdown2
            options={
              (searchParams.get("wingId") &&
                flatsData?.manage_flat_details?.map((item) => ({
                  value: item?.flat_id,
                  label: item?.flat_no,
                }))) ||
              []
            }
            placeholder="Select Flat"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectFlat(selectedItem);
            }}
            value={
              searchParams.get("flatId") && {
                value: searchParams.get("flatId"),
                label: flatsData?.manage_flat_details?.find(
                  (item) =>
                    item.flat_id?.toString() === searchParams.get("flatId")
                )?.flat_no,
              }
            }
          />
          {/* <Dropdown2
            options={debitorType}
            placeholder="Debitor Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            // onSelect={(selectedItem) => {
            //   handelSelectStatus(selectedItem);
            // }}
            value={
              searchParams.get("status") && {
                value: searchParams.get("status"),
                label: debitorType?.[searchParams.get("status")],
              }
            }
          />
          <Dropdown2
            options={status}
            placeholder="Status"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              // handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("bill_type") && {
                value: searchParams.get("bill_type"),
                label: status?.[searchParams.get("bill_type")],
              }
            }
          /> */}
          {/* <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.debitor_type
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.debitor_type
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Debitor Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectDebitorType(selectedItem);
            }}
            value={
              searchParams.get("debitor_type") && {
                value: searchParams.get("debitor_type"),
                label:
                  manageDebitNoteCurrent?.data?.debitor_type?.[
                    searchParams.get("debitor_type")
                  ],
              }
            }
          /> */}

          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        {/* <div>
          <div className="flex justify-start gap-3 items-center my-0 p-1 mb-2">
            <div className="text-sm">Due Filter</div>
            <FilterTextField
              defaultFromValue={defaultFromValue && defaultFromValue}
              defaultToValue={defaultToValue && defaultToValue}
              onAmountFromChange={(e) => {
                setDefaultFromValue(e.target.value);
              }}
              onAmountToChange={(e) => {
                setDefaultToValue(e.target.value);
              }}
            />
            <Button
              label="Search"
              type="button"
              onClick={() => {
                handleAmountChange();
              }}
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              disabled={!defaultFromValue || !defaultToValue}
            />
            <Button
              label="Reset"
              type="button"
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              onClick={() => handleAmountChange(true)}
              disabled={!defaultFromValue || !defaultToValue}
            />
          </div>
        </div> */}
        <div>
          <PrevInvoiceInterestReportTable
            loading={loading}
            data={invoiceInterestReport?.data?.map((item, index) => ({
              sNo:
                invoiceInterestReport?.paginate?.current *
                  invoiceInterestReport?.paginate?.limit +
                1 +
                index,
              invoiceNo: item["Invoice No."],
              memberDetails: item["Member Details"],
              invoiceDate: item["Invoice Date"],
              generationDate: item["Generation Date"],
              item: item["Item"],
              actualAmount: item["Actual Amount"],
              billAmount: item["Bill Amount"],
            }))}
            columns={headCells}
            checkbox={false}
            pagination={invoiceInterestReport?.data?.length}
            totalCount={invoiceInterestReport?.paginate?.total_count}
            totalPages={invoiceInterestReport?.paginate?.total_page}
            start={
              invoiceInterestReport?.paginate?.current *
                invoiceInterestReport?.paginate?.limit +
              1
            }
            end={
              invoiceInterestReport?.paginate?.current *
                invoiceInterestReport?.paginate?.limit +
              invoiceInterestReport?.data?.length
            }
            currentPage={Number(invoiceInterestReport?.paginate?.current)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
            selected={selected}
            setSelected={setSelected}
          ></PrevInvoiceInterestReportTable>

          {!invoiceInterestReport?.data?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default PrevInvoiceInterestReport;
